<template>
  <div class="bbs-wrap">
    <div class="bbs">
      <div class="bbs-main">
        <div class="banner" v-if="banner">
          <a-image :width="906" :height="256" :src="banner" />
        </div>
        <div class="content">
          <typeTab @tabChange="tabChange" @search="tabSearch" @sort="tabSort" />
          <a-spin :spinning="loading">
            <div
              class="list"
              v-infinite-scroll="handleInfiniteOnLoad"
              :infinite-scroll-immediate-check="false"
              :infinite-scroll-disabled="scrollDisabled"
              infinite-scroll-watch-disabled="scrollDisabled"
              :infinite-scroll-distance="50"
            >
              <div
                class="list-item"
                v-for="item in subjectList"
                :key="item.subjectId"
              >
                <posts
                  :newWindow="newWindow"
                  :data="item"
                  @previewVideo="(url) => $refs.previewVideoRef.openModal(url)"
                />
              </div>
              <a-empty
                v-if="!subjectList.length && !loading"
                style="padding: 50px 0"
              />
            </div>
          </a-spin>
          <div class="load-more" v-if="subjectList.length && !loading">
            {{ scrollDisabled ? $t("bbs.no_more") : $t("LB_Doc_Mobile_Load") }}
            <!-- "已经到底了" : "加载中..." -->
          </div>
        </div>
      </div>
      <div class="bbs-sidebar">
        <sidePost @post="post" />
        <div class="bbs-sidebar-box">
          <sideMine :newWindow="newWindow" />
        </div>
        <div class="bbs-sidebar-box">
          <sideGroup
            :userInfo="userInfo"
            :newWindow="newWindow"
            @addGroup="$refs.modalGroupRef.openModal()"
          />
        </div>
        <sideTopic :newWindow="newWindow" />
        <sideQuestion :newWindow="newWindow" />
      </div>
    </div>
  </div>
  <modalGroup ref="modalGroupRef" :coverList="coverList" @handleOk="addGroup" />
  <modalPosts
    ref="modalPostsRef"
    :communityConfig="communityConfig"
    @handleOk="postEnd(1)"
  />
  <modalQuestion
    ref="modalQuestionRef"
    :communityConfig="communityConfig"
    @handleOk="postEnd(2)"
  />
  <modalArticle
    ref="modalArticleRef"
    :communityConfig="communityConfig"
    @handleOk="postEnd(3)"
  />
  <previewVideo ref="previewVideoRef" />
</template>

<script>
import { reactive, toRefs, ref, computed, nextTick } from "vue";
import { useStore } from "vuex";

import ls from "@/utils/local-storage";

import { getBanner, getCoverList } from "@/api/other";
import {
  getCommunityConfig,
  getTopSubjectList,
  getSubjectList,
} from "@/api/bbs";

import tag from "./components/tag.vue";
import topList from "./components/topList.vue";
import typeTab from "./components/typeTab.vue";
import posts from "./components/posts.vue";
import sidePost from "./components/sidePost.vue";
import sideMine from "./components/sideMine.vue";
import sideGroup from "./components/sideGroup.vue";
import sideTopic from "./components/sideTopic.vue";
import sideQuestion from "./components/sideQuestion.vue";
import modalGroup from "./components/modalGroup.vue";
import modalPosts from "./components/modalPosts.vue";
import modalQuestion from "./components/modalQuestion.vue";
import modalArticle from "./components/modalArticle.vue";
import previewVideo from "./components/previewVideo.vue";

export default {
  components: {
    tag,
    topList,
    typeTab,
    posts,
    sidePost,
    sideMine,
    sideGroup,
    sideTopic,
    sideQuestion,
    modalGroup,
    modalPosts,
    modalQuestion,
    modalArticle,
    previewVideo,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      userInfo: computed(() => store.getters.userInfo),
      newWindow: computed(() => store.getters.companyInfo.useNewWindow == 1),
      loading: false,
      scrollDisabled: true,
      banner: "",
      coverList: [],
      communityConfig: {},
      subjectTopList: [],
      subjectParams: {
        status: 1,
        pageSize: 10,
        page: 1,
        order: 1,
        subjectType: 0,
      },
      subjectList: [],
    });

    getBanner().then((res) => {
      let list = res.data || [];
      list.forEach((item) => {
        if (item.bannerType === 31) {
          state.banner = item.coverPath;
        }
      });
    });

    getCoverList(47).then((res) => {
      state.coverList = res.data.sys;
    });

    getCommunityConfig().then((res) => {
      state.communityConfig = res.data;
    });

    const getTopData = async () => {
      const { keyword, subjectType } = state.subjectParams;
      const res = await getTopSubjectList({ groupid: 0, keyword, subjectType, });
      if (res.ret === 0) {
        let list = res.data || []
        state.subjectList.unshift(...list);
      }
    };

    const getSubject = async () => {
      if (state.subjectParams.page === 1) {
        state.loading = true;
      }
      state.scrollDisabled = true;
      
      getTopData();

      getSubjectList(state.subjectParams).then((res) => {
        state.loading = false;
        let list = res.data.list || [];
        state.subjectList.push(...list);
        if (state.subjectList.length < res.data.totals) {
          nextTick(() => {
            state.scrollDisabled = false;
          });
        } else {
          state.scrollDisabled = true;
        }
      });
    };
    getSubject();

    const tabChange = (type) => {
      state.subjectParams.subjectType = type;
      state.subjectParams.page = 1;
      state.subjectList = [];
      getSubject();
    };

    const tabSearch = (value) => {
      state.subjectParams.keyword = value;
      state.subjectParams.page = 1;
      state.subjectList = [];
      getSubject();
    };

    const tabSort = (value) => {
      state.subjectParams.order = value;
      state.subjectParams.page = 1;
      state.subjectList = [];
      getSubject();
    };

    const modalPostsRef = ref(null);
    const modalQuestionRef = ref(null);
    const modalArticleRef = ref(null);
    const post = (type) => {
      if (type === 1) {
        modalPostsRef.value.openModal();
      } else if (type === 2) {
        modalQuestionRef.value.openModal();
      } else if (type === 3) {
        modalArticleRef.value.openModal();
      }
    };

    const postEnd = (type) => {
      if (
        state.subjectParams.subjectType === type ||
        state.subjectParams.subjectType === 0
      ) {
        state.subjectParams.page = 1;
        state.subjectList = [];
        getSubject();
      }
    };

    const addGroup = (id) => {
      ls.set("NEWGROUP", 1);
      window.location.href = `/bbs/group?groupId=${id}`;
    };

    const handleInfiniteOnLoad = () => {
      if (!state.scrollDisabled) {
        state.subjectParams.page++;
        getSubject();
      }
    };

    return {
      ...toRefs(state),
      tabChange,
      tabSearch,
      tabSort,
      modalPostsRef,
      modalQuestionRef,
      modalArticleRef,
      post,
      postEnd,
      addGroup,
      handleInfiniteOnLoad,
    };
  },
};
</script>

<style lang="less" scoped>
.bbs-wrap {
  background-color: @color-page-gray;
}
.bbs {
  .mixinWrap();
  .mixinFlex(space-between);
  padding: 20px 0 80px;
  &-main {
    width: 906px;
    &-box {
      margin-bottom: 24px;
    }
  }
  &-sidebar {
    width: 270px;
    position: relative;
    &-box {
      margin-bottom: 24px;
    }
  }
  .banner {
    width: 906px;
    height: 256px;
    background-color: #fff;
    margin-bottom: 24px;
    ::v-deep(.ant-image-img) {
      height: 100%;
      border-radius: 8px;
    }
  }
  .content {
    width: 906px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
    .list {
      padding: 0 30px 24px;
      min-height: 500px;
      &-item {
        padding: 30px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      }
    }
    .load-more {
      text-align: center;
      font-size: 14px;
      color: #999;
      line-height: 20px;
      padding: 0 0 24px 0;
    }
  }
}
</style>
